#dialogContainer{
  height: 172px;
  width:456px;
  margin:auto;
  border-radius: 8px;
}
#dialogContainer .MuiDialog-paperScrollPaper {
    max-height: 100%;
}
#dialogContainer .MuiDialog-paperWidthSm {
    max-width: 100%;
}
#dialogContainer .MuiDialog-paper {
    margin: 0px;
}
#dialogContainer .dialog-sub-container{
 margin-left: 16px;
  margin-right: 16px;
}
#dialogContainer .share-view{
  margin-bottom: 0px;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
}
#dialogContainer .shareTxt {
  width: 54px ;
  height: 24px ;
  margin-top: 16px;
  font-family: SFProDisplay-Semibold;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal ;
  font-style: normal ;
  line-height: normal;
  letter-spacing: 0.46px;
  color: #000 ;
  text-align: left ;
}
#dialogContainer .share-close{
   height: 32px;
   width: 32px;
   margin-top: 8px;
}
#dialogContainer .share-container{
  margin-top: 11px;
  width: 100%;
  text-align: left;
}
#dialogContainer .icon-container {
  vertical-align: top ;
  display: inline-block;
  margin-right: 10px;
  text-align: center ;
}
#dialogContainer .share-button:hover:not(:active) {
  opacity: 0.75;
}
#dialogContainer .shareurl-button{
  display: flex;
  margin-top: 15px;
  flex-direction: row;
  padding: 9.7px 12px 9px 20px;
  border-radius: 8px;
  background-color: #f5f5f5;
}
#dialogContainer .shareurl-txt{
  width: 75% ;
  height: 20px;
  margin: 4.3px 42.7px 5px 0 ;
  font-family: Roboto-Regular;
  font-size: 14px ;
  font-weight: normal ;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43 ;
  letter-spacing: 0.1px ;
  color: rgba(0, 0, 0, 0.87);
}
#dialogContainer .copy-btn{
  width: 82px ;
  height: 28px ;
}
#dialogContainer .copy-txt{
  width: auto;
  height: 22px;
  font-family: SFProRounded-Medium;
  font-size: 15px ;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  text-align: center;
  color: #fff ;
}

@media only screen and (max-width: 768px){
  #dialogContainer{
  width:95% ;
  inset:unset !important;
  bottom:10px !important;
  position: absolute ;
  left:0 !important;
  right: 0 !important;
}
#dialogContainer .MuiDialog-paperScrollPaper {
    max-height: 100%;
}
#dialogContainer .MuiDialog-paperWidthSm {
    max-width: 100% ;
}
#dialogContainer .MuiDialog-paper {
    margin: 0px ;
}
}