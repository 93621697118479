.playlist-page {
  &-title {
    line-height: 1 !important;
    text-align: left;
    margin-bottom: 0.7em !important;
    @media only screen and (min-width: 2000px) {
      font-size: 70px !important;
      letter-spacing: -1.4px !important;
    }
  }
  // FOR SHIMMER
  &-shimmer {
    &-item {
      margin: 8px 0;
    }
    .line {
      border: solid 1px #e0e0e0;
      grid-column: 1/4;
      grid-row: 4;
    }
  }
  //
  &-container {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    text-align: left;
    .play-list-item {
      position: relative;
      display: inline-flex;
      flex-basis: 100%;
      margin-bottom: 32px;
      gap: 1em;
      @media only screen and (min-width: 1360px) {
        margin-bottom: 48px;
      }
      &-image {
        img {
          width: 115px;
          height: 64.6px;
          border-radius: 4px;
          @media only screen and (min-width: 768px) {
            width: 147px;
            height: 82.6px;
          }
          @media only screen and (min-width: 1360px) {
            width: 192px;
            height: 107.9px;
          }
          @media only screen and (min-width: 2000px) {
            width: 192px;
            height: 107.9px;
          }
        }
      }
      &-class {
        flex-basis: 80%;
        display: inline-flex;
        gap: 35px;
        @media only screen and (min-width: 1360px) {
          gap: 115px;
          margin-left: 20px;
        }
        @media only screen and (min-width: 2000px) {
          margin-left: 20px;
        }
        &-info {
          font-family: SFProDisplay-Regular;
          // flex-basis: 20%;
          width: 60px;
          @media only screen and (min-width: 1360px) {
            width: 71px;
          }
          @media only screen and (max-width: 767px) {
            flex-basis: 80%;
          }
          &.end {
            flex-basis: 60%;
          }
          h4 {
            display: none;
            margin: 0px;
            font-family: SFProDisplay-Semibold;
            font-size: 17px;
            font-weight: normal !important;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.41;
            letter-spacing: -0.2px;
            color: #000;
            @media only screen and (max-width: 767px) {
              // display: block;
              display: -webkit-box !important;
            }
            @media only screen and (min-width: 768px) {
              font-size: 13px;
              line-height: 1.85;
              letter-spacing: -0.15px;
            }
            @media only screen and (min-width: 1360px) {
              font-size: 17px;
              line-height: 1.41;
              letter-spacing: -0.2px;
            }
            @media only screen and (min-width: 2000px) {
              font-size: 17px;
              line-height: 1.41;
              letter-spacing: -0.2px;
            }
          }
          h3 {
            font-size: 13px;
            //font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.85;
            letter-spacing: -0.15px;
            color: #000;
            margin: 0px;
            font-family: SFProDisplay-Semibold;
            @media only screen and (max-width: 767px) {
              display: -webkit-box !important;
            }
            @media only screen and (min-width: 768px) {
              font-size: 13px;
            }
            @media only screen and (min-width: 1360px) {
              font-size: 17px;
              line-height: 1.41;
              letter-spacing: -0.2px;
            }
            @media only screen and (min-width: 2000px) {
              font-size: 17px;
              line-height: 1.41;
              letter-spacing: -0.2px;
            }
          }
          &-time {
            font-family: SFProDisplay-Regular;
            font-size: 13px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.38;
            letter-spacing: -0.23px;
            color: #8e8e93;
            @media only screen and (min-width: 768px) {
              font-size: 13px;
            }
            @media only screen and (min-width: 1360px) {
              font-size: 17px;
              line-height: 1.06;
            }
            @media only screen and (min-width: 2000px) {
              font-size: 17px;
              line-height: 1.06;
            }
          }
          &-desc {
            font-family: SFProDisplay-Regular;
            font-size: 13px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: -0.3px;
            @media only screen and (min-width: 768px) {
              font-size: 13px;
              line-height: 1.38;
            }
            @media only screen and (min-width: 1360px) {
              font-size: 17px;
              letter-spacing: -0.3px;
              line-height: normal;
            }
            @media only screen and (min-width: 2000px) {
              font-size: 17px;
              line-height: normal;
              letter-spacing: -0.3px;
            }
          }
        }
      }
      &:not(:last-child)::after {
        content: "";
        border-bottom: solid 1px #e0e0e0;
        width: 100%;
        position: absolute;
        bottom: -16px;
        height: 16px;
        background-color: #fff !important;
        @media only screen and (min-width: 768px) {
          bottom: -13px;
          height: 13px;
          background-color: #fff !important;
        }
        @media only screen and (min-width: 1360px) {
          bottom: -24px;
          height: 24px;
          background-color: #fff !important;
        }
        left: 0;
      }
      &:hover {
        background-color: rgba(142, 142, 147, 0.08);
        cursor: pointer;
        @media only screen and (max-width: 1359px) {
          background-color: #fff !important;
        }
      }
      // @media only screen and (max-width: 810px) {
      //   padding-block: 1.5em;
      // }
    }
    .no-class {
      font-size: 17px;
    }
  }
}
.playerlist-image,
.playlist-item {
  line-height: 0% !important;
}
@media only screen and (max-width: 767px) {
  .play-list-item-class-info {
    display: block !important;
  }
  .playlist-page-title {
    display: none;
  }
  .playerlist-image {
    margin-top: 5px !important;
  }
  .player-right-side {
    padding-left: 11px !important;
  }
  .playerlist-mobile-view {
    display: none !important;
  }
  .player-list-info .play-list-item-image::before {
    width: 18.9px;
    height: 18.9px;
    margin-top: 0px;
    font-size: 11px !important;
  }
  .h4-title {
    padding-top: 1px !important;
  }
  .h3-title {
    position: relative;
    top: 5px;
  }
  .details-h3-title {
    position: relative;
    top: 0px !important;
  }
  .time-title {
    position: relative;
    top: 2px;
  }
  .playlist-page-title {
    display: none;
  }
  .m-t-32 {
    margin-top: 32px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1359px) {
  .play-list-item-class {
    padding-left: 13px !important;
  }
  .end {
    // padding-left: 25px !important;
  }
  .playlist-page-title {
    display: none;
  }
}
@media only screen and (min-width: 1360px) and (max-width: 1999px) {
  .play-list-item-class {
    padding-left: 23px !important;
  }
  .end {
    // padding-left: 110px !important;
  }
}
@media only screen and (min-width: 1360px) {
  .playlist-details-classes-lists {
    width: 75px !important;
  }

  .playlist-details-page .playlist-page-title {
    font-weight: 400 !important;
  }
}
@media only screen and (min-width: 2000) {
  .playlist-page-title {
    letter-spacing: -1.4px !important;
  }
  .playlist-page-container .play-list-item .player-right-side {
    margin-left: 30px !important;
  }
}
