// MODAL FOR EDIT
.playlist-modal-delete {
  [role="dialog"] {
    overflow: hidden;
    width: 800px;
    margin: 0;
    padding: 0;
    @media only screen and (max-width: 1359px) {
      width: 600px;
    }
    @media only screen and (max-width: 767px) {
      min-height: 100%;
      min-width: 100%;
      border-radius: 0;
    }
  }
  .play-list-delete-modal-container {
    .play-list-delete-modal-text {
      width: 45em;
      margin: auto;
      .modal-details h5 {
        margin-top: 36px;
        margin-bottom: 61px;
      }
      span h5 {
        font-family: SFProDisplay-regular;
        font-size: 17px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.3px;
        color: #8e8e93;
      }
      @media only screen and (max-width: 767px) {
        width: 100%;
      }
    }
    &.loading {
      pointer-events: none;
    }

    @media only screen and (max-width: 767px) {
      margin-top: 15px;
    }
    .ft-20 {
      font-size: 20px !important;
    }
    @media only screen and (max-width: 2000px) {
      .playlist-modal-root {
        max-height: 720px !important;
        overflow-y: scroll;
      }
    }
  }
  @media only screen and (min-width: 1359px) {
    .modal-delete-button {
      margin-bottom: 45px !important;
    }
  }
  @media only screen and (max-width: 767px) {
    .MuiButton-label .MuiTypography-h5 {
      font-family: SFProDisplay-Semibold !important;
      font-size: 20px;
      line-height: 1.2;
      letter-spacing: normal;
    }
  }
  @media only screen and (min-width: 768px) {
    .MuiButton-label .MuiTypography-h5 {
      font-family: SFProDisplay-Semibold !important;
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: normal;
    }
  }
  @media only screen and (min-width: 2000px) {
    .MuiButton-label .MuiTypography-h5 {
      font-family: SFProDisplay-Semibold !important;
    }
  }
}
@media only screen and (max-width: 767px) {
  .playlist-modal-delete {
    .MuiDialog-container .MuiDialog-paper .modal-title {
      margin-top: 30px !important;
    }
  }
  .modal-delete-button {
    display: flex;
    justify-content: center;
  }
  .modal-delete-button .MuiButtonBase-root {
    position: absolute;
    bottom: 34px;
  }
  .playlist-modal-delete
    .play-list-delete-modal-container
    .play-list-delete-modal-text
    .modal-details
    h5 {
    margin-top: 25px;
  }
  .edit-button {
    display: flex;
    justify-content: center;
  }
  .edit-modal-button {
    position: absolute !important;
    bottom: 34px !important;
  }
  .playlist-modal-root {
    .MuiDialog-container .MuiDialog-paper .modal-title {
      margin-top: 30px !important;
    }
  }
  .edit-modal-button .MuiButton-label .MuiTypography-h5 {
    font-family: SFProDisplay-Semibold !important;
    font-size: 20px !important;
    font-weight: normal !important;
    line-height: 1.2 !important;
    letter-spacing: normal !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1359px) {
  .edit-button {
    margin-top: 31px !important;
  }
  .edit-modal-button .MuiButton-label .MuiTypography-h5 {
    font-family: SFProDisplay-Semibold !important;
  }
}
